<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal, BFormCheckbox, BFormFile } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BFormCheckbox,
    BFormFile,
  },
  data() {
    return {
      pageArrayStock: [], // for Frontend Search
      pageArray: [],

      addModal: false,
      addObject: {
        brandName: '',
        brandVisible: false,
        brandImage: {},
      },
      addImageView: '',

      editModal: false,
      editObject: {
        brandId: '',
        brandName: '',
        brandImage: {},
      },
      editImageView: '',

      filterName: '',

      viewMedia: '',
      mediaModal: false,
    }
  },
  async created() {
    await this.mountedData()
  },
  methods: {
    async mountedData() {

      // Clear Variables..
      this.addModal = false
      this.editModal = false
      this.addObject = {
        brandName: '',
        brandVisible: false,
        brandImage: {}
      }

      this.filterFirstname = ''
      this.filterLastname = ''

      // Get List
      await this.$http.get('/features/brand/all')
        .then(response => {
          this.pageArrayStock = this.pageArray = response.data
        })
        .catch(() => {
          alert('ERROR! PLEASE CONTACT TO DEVELOPER')
        })
    },
    async tableInteractive(item, index, type) {
      if (type === 'inside') {
        this.$router.push(`/carModel/${item.brandId}`)
      }

      if (type === 'edit') {
        this.editObject = structuredClone(item)
        this.editModal = true
      }

      if (type === 'active') {
        this.$swal.fire({
          title: item.brandVisible ? 'Aracı Pasif Yapıyorsunuz' : 'Aracı Aktif Yapıyorsunuz',
          text: 'Aracın görünürlüğünü değiştiriyorsunuz.',
          icon: 'error',
          showCancelButton: true,
          cancelButtonText: 'İptal Et',
          cancelButtonColor: '#B72424',
          confirmButtonText: 'Devam Et',
          confirmButtonColor: '#26B724'
        })
          .then(async response => {
            if (response.isConfirmed) {
              await this.$http.put('features/brand/visible', {
                brandId: item.brandId,
                brandVisible: !item.brandVisible
              })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  this.mountedData()
                })
                .catch(error => {
                  let errorMessage = ''
                  switch (error.response.data) {
                    case 'CannotDeleteYourself':
                      errorMessage = 'Cannot Delete Yourself'
                      break
                    case 'PermissionError':
                      errorMessage = 'Permission Error!'
                      break
                  }
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: errorMessage,
                      icon: 'XIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
      }
    },
    async saveData(type) {
      if (type === 'create') {

        const sendObject = {
          brandName: this.addObject.brandName,
          brandVisible: this.addObject.brandVisible
        }

        await this.$http.post('features/brand/create', sendObject)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mountedData()
          })
          .catch(error => {
            let errorMessage = ''
            switch (error.response.data) {
              case 'BadValidation':
                errorMessage = 'Brand Name To Short'
                break
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }

      if (type === 'update') {

        const sendObject = {
          brandId: this.editObject.brandId,
          brandName: this.editObject.brandName
        }

        await this.$http.put('features/brand/update', sendObject)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success, Please Wait..',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.mountedData()
          })
          .catch(error => {
            let errorMessage = ''
            switch (error.response.data) {
              case 'AdminEmailIsRegistered':
                errorMessage = 'Admin Email is Valid'
                break
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMessage,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
    async filterNow() {
      this.pageArray = this.pageArrayStock.filter(x => x.brandName.toLocaleLowerCase()
        .includes(this.filterName.toLocaleLowerCase()))
    },
    imagePreview(event, type) {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        if (type === 'add') {
          this.addImageView = fileReader.result
        } else {
          this.editImageView = fileReader.result
        }
      }
      fileReader.readAsDataURL(event.target.files[0])

      if (type === 'add') {
        this.addObject.brandImage = event.target.files[0]
      } else {
        this.editObject.brandImage = event.target.files[0]
      }
    },
    deleteImage(type) {
      if (type === 'add') {
        this.addImageView = ''
        this.addObject.brandImage = {}
      } else {
        this.editImageView = ''
        this.editObject.brandImage = {}
      }
    },
    openMedia(mediaUrl) {
      this.viewMedia = mediaUrl
      this.mediaModal = true
    },
  },
  watch: {
    filterName: {
      handler() {
        this.filterNow()
      }
    },
  }
}
</script>

<template>
  <section class="pb-2">
    <div class="tb-card tb-flex-vertical">
      <a class="tb-page-title">Car Brands ({{ pageArrayStock.length }})</a>
      <button class="tb-button bg-primary ml-auto" @click="addModal = true">
        <a class="text-white">Create Car Brand</a>
      </button>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
          <tr>
            <th style="width: 5%"><input type="text" class="form-control" placeholder="#" disabled readonly></th>
            <!--
          <th style="width: 15%"><input type="text" class="form-control" placeholder="Brand Image" disabled readonly>
          </th>
          -->
            <th style="width: 70%"><input type="text" class="form-control" placeholder="Brand Name"
                v-model="filterName">
            </th>
            <th style="width: 25%"><input type="text" class="form-control" placeholder="Options" disabled readonly></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in pageArray">
            <td><a>{{ index + 1 }}</a></td>
            <!--
          <td>
            <img v-if="item.brandImage" :src="item.brandImage"
                 class="w-100 h-120-px tb-img-cover tb-border-radius-0-5-rem tb-hover-pointer" alt=""
                 @click="openMedia(item.brandImage)"
            >
            <div v-else class="w-100 h-120-px bg-primary tb-flex-full tb-border-radius-0-5-rem tb-hover-pointer">
              <feather-icon icon="ImageIcon" size="32" class="text-white"/>
            </div>
          </td>
          -->
            <td><a>{{ item.brandName }}</a></td>
            <td>
              <button class="tb-button bg-success" @click="tableInteractive(item, index, 'inside')">
                <span class="text-white">Inside</span>
              </button>
              <button class="tb-button bg-primary tb-ml-5-px" @click="tableInteractive(item, index, 'edit')">
                <span class="text-white">Edit</span>
              </button>
              <button class="tb-button tb-ml-5-px" :class="item.brandVisible ? 'bg-info' : 'bg-danger'"
                @click="tableInteractive(item, index, 'active')">
                <span class="text-white">Active</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Add Modal -->
    <b-modal v-model="addModal" hide-header hide-footer centered size="lg">
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Create Car Brand</a>
          <hr>
        </div>
        <div class="col-12">
          <label>Brand Name</label>
          <input type="text" class="form-control" name="brandName" v-model="addObject.brandName">
        </div>
        <div class="col-12 mt-2">
          <b-form-checkbox checked="true" class="custom-control-success" v-model="addObject.brandVisible"
            name="brandVisible" switch>Brand Active
          </b-form-checkbox>
          <label>Brand, Active ise tüm kullanıcılarda görünecektir. Pasif durumda ise Store'de
            gözükmeyecektir.</label>
        </div>
        <!--
          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <label>Brand Image</label>
                <b-form-file
                    @change="imagePreview($event, 'add')"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                />
              </div>
              <div v-if="addImageView" class="col-12 mt-2 px-4">
                <img class="img-fluid tb-radius-5 shadow" :src="addImageView" alt="">
                <button class="btn btn-block btn-danger btn-sm mt-1" @click="deleteImage('add')">Delete</button>
              </div>
            </div>
          </div>
        -->
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1" :disabled="!addObject.brandName" @click="saveData('create')">
            Create & Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal v-model="editModal" hide-header hide-footer centered size="lg">
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Update Car Brand</a>
          <hr>
        </div>
        <div class="col-12">
          <label>Brand Name</label>
          <input type="text" class="form-control" name="brandName" v-model="editObject.brandName">
        </div>
        <!--
          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <label>Brand Image</label>
                <b-form-file
                    @change="imagePreview($event, 'edit')"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"

                />
              </div>
              <div v-if="editImageView" class="col-12 mt-2 px-4">
                <img class="img-fluid tb-radius-5 shadow" :src="editImageView" alt="">
                <button class="btn btn-block btn-danger btn-sm mt-1" @click="deleteImage('edit')">Delete</button>
              </div>
            </div>
          </div>
        -->
        <div class="col-12 text-center">
          <hr>
          <b-button variant="outline-primary" class="mt-1" :disabled="!editObject.brandName"
            @click="saveData('update')">Update & Close
          </b-button>
        </div>
      </div>
    </b-modal>

    <!-- Media Modal -->
    <b-modal v-model="mediaModal" hide-header hide-footer centered size="lg">
      <div class="row py-1">
        <div class="col-12 text-center">
          <img :src="viewMedia" class="w-100 img-fluid" alt="">
        </div>
      </div>
    </b-modal>

  </section>
</template>
